#heading-bar {
  max-width: 1700px;
  height: 80px;
  position: relative;
  top: 0;
  left: 0vw;
  background-color: #eaeaea;
  padding: 20px 50px;
  font-size: 28px;
  font-weight: 500;
}

#tab-menu > div {
  height: 50px;
}

#tab-menu a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  #tab-menu {
    width: 80vw;
    padding: 0 10px;
  }
}

@media screen and (min-width: 576px) {
  #tab-menu {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  #heading-bar {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 767px) {
  #heading-bar {
    padding: 20px 10px;
  }
}

/* Product Detail Page */
#Prod-Detil {
  width: 700px;
  margin: 30px auto;
}

#Go-Back {
  width: 100px;
}

@media screen and (max-width: 768px) {
  #Prod-Detil {
    width: 300px;
  }
}