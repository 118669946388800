nav {
  width: 100%;
  display: flex;
  justify-content: flex-start !important;
  align-items: baseline;
  padding: 0 50px 30px 50px;
}

#collapse-wrap {
  flex: 0;
}

#menu-expand {
  flex: 1;
}

h1 {
  flex: 6;
  text-align: center;
}

#collapse-menu {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.navbar-nav:first-child a {
  padding-left: 0 !important;
}

.navbar-brand {
  font-size: 30px;
  font-weight: 700;
}

#icons {
  display: flex;
  flex: 1;
  text-align: right;
}

#icons > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
}

#icons a {
  color: #232323;
}

#icons > div:hover, #icons a:hover {
  color: #8d8d8d;
}

#search {
  font-size: 22px;
}

#user {
  font-size: 26px;
}

#cart {
  font-size: 23px;
  position: relative;
  top: 0;
  left: 0;
}

#cart-mark {
  position: absolute;
  top: -5px;
  left: 30%;
  color: gray;
  font-size: 10px;
}

.navbar-nav a {
  font-size: 15px;
  font-weight: 600;
  color: #232323 !important;
}

.navbar-nav a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 400px) {
  #menu-toggler:checked ~ #collapse-menu {
    top: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  nav {
    padding: 0 !important;
  }

  nav .navbar-brand {
    margin-right: 0 !important;
  }

  #collapse-wrap {
    flex: 1;
  }

  #menu-toggler:checked ~ #collapse-menu {
    display: block;
    list-style: none;
    width: 100vw;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 990;
    background-color: #ffffff;
    padding: 30px 50px 20px 20px;
    border-bottom: 2px solid #dfdfdf;
  }

  #collapse-menu .nav-item {
    height: 35px;
    margin-bottom: 15px;
  }

  #collapse-menu .nav-item:last-child {
    margin-bottom: 20px;
  }

  #collapse-menu a {
    color: black;
    text-decoration: none;
  }

  .navbar-toggler {
    border: none;
    padding: 5px 15px 5px 10px;
  }

  h1 {
    flex: 3;
  }

  h1 > a {
    font-size: 22px !important;
  }

  #icons {
    flex: 1;
  }

  #icons > div:last-child {
    padding-right: 15px;
  }

  #icons > div {
    margin-left: 20px;
  }
}