/* General Layout */
body {
  font-family: 'Poppins', sans-serif;
}

#wrap {
  width: 100%;
  margin: 10px auto 0 auto;
}

main {
  width: 100%;
}

/* Footer */
#Footer {
  width: 88%;
  margin: 20px auto;
}

#Footer p {
  font-size: 10px;
  letter-spacing: 2px;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  color: gray;
}

#Footer hr {
  border: 0.5px solid #cfcfcf;
}

@media screen and (min-width: 1800px) {
  #wrap {
    max-width: 1700px;
    margin: 20px auto 0 auto;
  }
}

@media screen and (max-width: 768px) {
  #wrap {
    margin: 20px 0 0 0;
  }
}

/* Login & Sign up Page */
.user-form-wrap {
  width: 500px;
  margin: 80px auto 0 auto;
  text-align: center;
}

.user-formgroup {
  margin: 28px 0;
}

.user-formgroup > input {
  margin-bottom: 25px;
}

.user-form-wrap button {
  margin-top: 20px;
}

#Success {
  margin-top: 30px;
}

#Error {
  margin-top: 30px;
}

#Error i {
  font-size: 30px;
}

/* Dialog */
#Dialog {
  width: 250px;
  min-height: 280px;
  position: absolute;
  top: 66px;
  right: 50px;
  z-index: 999;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: 8px 6px 6px #d1d1d1;
  padding: 50px 25px 25px 25px;
}

#Dialog-content {
  text-align: center;
}

#Dialog-content p {
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}

.close-button  {
  width: 50px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 1px;
  text-align: center;
  font-size: 36px;
  color: #535353;
}

.close-button:hover {
  cursor: pointer;
  color: gray;
}

#Dialog-content button {
  width: 110px;
}