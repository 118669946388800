#Panel {
  padding: 30px 50px;
}

#Panel-Body {
  margin-bottom: 30px;
}

/* Product Management Page */
#Product-Manager img {
  width: 80px;
  height: 100px;
}

#product-actions > button {
  display: block;
  width: 100px;
  margin: 10px 0 20px 0;
  font-size: 16px;
  font-weight: 600;
}

/*   New Product    */
#Create-Product {
  margin: 50px auto 30px auto;
}

#Create-Product form {
  width: 300px;
  margin: 0 auto;
}

.form-group {
  margin: 15px 0;
}

/*   ProductPreview   */
#Product-Preview {
  width: 300px;
  margin: 0 auto;
}

#Product-Preview > div {
  padding: 0;
}

#Product-Preview > div:hover {
  transform: scale(1);
}

@media screen and (max-width: 768px) {
  #Panel {
    padding: 15px 5px;
  }

  #Panel-Body {
    width: 100%;
  }

  #Create-Product form {
    width: 330px;
  }

  #Create-Product > div {
    padding: 0;
  }

  #Product-Preview {
    width: 310px;
  }
}

/* Edit Prosuct Dialog */
#Edit-Dialog {
  width: 500px;
  height: 75vh;
  overflow-y: auto;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #fff;
  position: fixed;
  top: 80px;
  left: 33vw;
  padding: 15px 20px;
  box-shadow: 1px 1px 1px #c6c6c6;
}

#preview-img {
  width: 60px !important;
  height: 60px !important;
}

/* Dash Board */
#Dashboard {
  padding-top: 20px;
}

#polar-chart {
  width: 600px;
  margin: 0 auto;
}