#Home-Page {
  margin: 20px 50px;
}

.row > div {
  max-height: 500px;
  margin-bottom: 20px;
  overflow-y: hidden;
}

img {
  width: 100%;
  height: auto;
}

#featured-product {
  position: relative;
  top: 0;
  left: 0;
}

#heading {
  position: absolute;
  top: 50px;
  left: 45px;
}

#heading > h2 {
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 20px;
}

#heading p {
  width: 66px;
  padding-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 2px solid;
}

#featured-product a {
  text-decoration: none;
  color: #232323 ;
  font-weight: 600;
}

#featured-product a:hover h2 {
  font-weight: 500;
}

#featured-product a:hover p {
  font-weight: 800;
}

#featured-product a:hover img {
  opacity: 0.85;
}

#other-category {
  margin: 0;
  padding: 0;
}

#other-category > div:first-child {
  padding-left: 0;
}

#other-category > div:last-child {
  padding-right: 0;
}

#other-category > div > a {
  display: block;
  height: 80px;
  color: #232323;
  background-color: #f0f0f0;
  padding: 20px 30px;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none;
}

#other-category a:hover {
  cursor: pointer;
}

#other-category i {
  font-size: 22px;
}

#other-category a:hover, #other-category a:hover > i {
  font-weight: 600 !important;
}

/* Tabs */
#tab-menu {
  margin: 30px auto;
  display: flex;
  overflow-x: auto;
}

.tab {
  width: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0 30px;
}

.tab:hover {
  cursor: pointer;
  color: #474747;
}

.active-tab {
  border-bottom: 2px solid black;
}

/* Tab Pane */
#tab-pane {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#product:first-child {
  padding-left: 0 !important;
}

#product:last-child {
  padding-right: 0;
}

#product {
  width: 270px;
  flex: 0 1 270px;
  padding: 10px 15px;
  text-align: center;
}

#product img {
  width: 100%;
  height: 260px;
}

#product > a {
  color: black;
  text-decoration: none;
}

#product:hover {
  transform: scale(1.1);
}

#product-name {
  margin-top: 30px;
}

#product-price {
  margin-top: 10px;
  font-weight: 600;
}

#tab-pane h6 {
  margin: 80px 0;
}

/* Media Query */
@media screen and (max-width: 768px) {
  #Home-Page {
    margin: 25px 10px 10px 10px;
  }

  #heading {
    top: 30px;
    left: 45px;
  }

  #other-category > div {
    padding: 0;
  }

  #product  {
    flex: 0 1 325px;
  }

  #Home-Page #tab-menu {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  #Home-Page {
    margin: 25px 50px 10px 50px;
  }
}

@media screen and (min-width: 768px) {
  #other-category > div:first-child {
    padding-right: 10px;
  }

  #other-category > div:last-child {
    padding-left: 10px;
  }
}